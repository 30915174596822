const EventCardData = [
    
    {
        id: 35,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykportal/Winter_Retreat_2024.jpg",
        title: "Winter Retreat ",
        lablename: "Register Now ",
        links: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        pagelinks: "",
        enddate:'02/01/2025',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykportal/Winter_Retreat_2024.jpg"
        
    },

    {
        id: 34,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2024.jpg",
        title: "Thanksgiving Weekend Devotional Retreat ",
        lablename: "Register Now ",
        links: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        pagelinks: "",
        enddate:'02/12/2024',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2024.jpg"
        
    },

    {
        id: 33,
        imgsrc: "https://yugalkunj.org/images/sharad%20Poornima.jpeg",
        title: "Sharad Poornima",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/ramayan.png",
        pagelinks: "",
        enddate:'17/10/2024',
        imglinks: "https://yugalkunj.org/images/ramayan.png"
        
    },

    {
        id: 32,
        imgsrc: "https://yugalkunj.org/images/ramayan.png",
        title: "Ramayan & Exclusive Devotion",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/ramayan.png",
        pagelinks: "",
        enddate:'28/10/2024',
        imglinks: "https://yugalkunj.org/images/ramayan.png"
        
    },

    
 
    
    
    
    {
        id: 31,
        imgsrc: "https://yugalkunj.org/images/diwali.jpeg",
        title: "Divali Celebrations",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/diwali.jpeg",
        pagelinks: "",
        enddate:'30/09/2024',
        imglinks: "https://yugalkunj.org/images/diwali.jpeg"
        
    },


 
    {
        id: 30,
        imgsrc: "https://yugalkunj.org/images/goverdhanpooja.jpeg",
        title: "Govardhan Pooja",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/goverdhanpooja.jpeg",
        pagelinks: "",
        enddate:'03/11/2024',
        imglinks: "https://yugalkunj.org/images/goverdhanpooja.jpeg"
        
    },

   
    
    {
        id: 29,
        imgsrc: "https://yugalkunj.org/images/pathofgod.jpeg",
        title: "The Only Path To God",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/pathofgod.jpeg",
        pagelinks: "",
        enddate:'02/06/2024',
        imglinks: "https://yugalkunj.org/images/pathofgod.jpeg"
        
    },



    {
        id: 28,
        imgsrc: "https://yugalkunj.org/images/Cleveland-Retreat.jpg",
        title: "Cleveland Ohio Retreat",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/Cleveland-Retreat.jpg",
        pagelinks: "",
        enddate:'28/05/2024',
        imglinks: "https://yugalkunj.org/images/Cleveland-Retreat.jpg"
        
    },

    {
        id: 27,
        imgsrc: "https://yugalkunj.org/images/Holika.png",
        title: "Mahaprabhu Jayanti & Holika Dahan",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/Holika.png",
        pagelinks: "",
        enddate:'26/03/2024',
        imglinks: "https://yugalkunj.org/images/Holika.png"
        
    },

  
    {
        id: 26,
        imgsrc: "https://yugalkunj.org/images/Holi_banner.jpeg",
        title: "Holi ! Celebrations ",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/Holi_banner.jpeg",
        pagelinks: "",
        enddate:'25/03/2024',
        imglinks: "https://yugalkunj.org/images/Holi_banner.jpeg"
        
    },
   

    {
        id: 28,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykportal/YK%20GSM%20-%20Brochure_2024.jpg",
        title: "Global Seva Mission",
        lablename: "View Flyer ",
        links: "https://stssevastorage.blob.core.windows.net/ykportal/Global%20Seva_2024.pdf",
        pagelinks: "",
        enddate:'24/07/2024',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykportal/Global%20Seva_2024.pdf"
        
    },

    {
        id: 25,
        imgsrc: "https://yugalkunj.org/images/MahaShivRatri.jpeg",
        title: "Maha Shivratri ",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/MahaShivRatri.jpeg",
        pagelinks: "",
        enddate:'09/03/2024',
        imglinks: "https://yugalkunj.org/images/MahaShivRatri.jpeg"
        
    },

    {
        id: 24,
        imgsrc: "https://yugalkunj.org/images/springbreakcampkids.jpeg",
        title: "Spring Break Kids Camp ",
        lablename: "Register Now ",
        links: "hhttps://ykportal.yugalkunj.org/Home/CountrySelect",
        pagelinks: "",
        enddate:'07/04/2024',
        imglinks: "https://yugalkunj.org/images/springbreakcampkids.jpeg"
        
    },

    {
        id: 23,
        imgsrc: "https://yugalkunj.org/images/Summer_camp_2024.png",
        title: "Summer Camp ",
        lablename: "Register Now ",
        links: "hhttps://ykportal.yugalkunj.org/Home/CountrySelect",
        pagelinks: "",
        enddate:'4/08/2024',
        imglinks: "https://yugalkunj.org/images/Summer_camp_2024.png"
        
    },

    {
        id: 24,
        imgsrc: "https://yugalkunj.org/images/milwaukee_summer_camp.png",
        title: "MilWaukee Camp ",
        lablename: "Register Now ",
        links: "hhttps://ykportal.yugalkunj.org/Home/CountrySelect",
        pagelinks: "",
        enddate:'18/08/2024',
        imglinks: "https://yugalkunj.org/images/milwaukee_summer_camp.png"
        
    },

    {
        id: 22,
        imgsrc: "https://yugalkunj.org/images/FamilyRetreat.jpg",
        title: "Family Day Weekend Retreat",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/FamilyRetreat.jpg",
        pagelinks: "",
        enddate:'21/02/2024',
        imglinks: "https://yugalkunj.org/images/FamilyRetreat.jpg"
        
    },

    {
        id: 11,
        imgsrc: "https://yugalkunj.org/images/philospy.png",
        title: "Philosophy of Divine Bless ",
        lablename: "View Flyer ",
        links: "https://yugalkunj.org/images/philospy.png",
        pagelinks: "",
        enddate:'15/02/2024',
        imglinks: "https://yugalkunj.org/images/philospy.png"
        
    },

    
    {
        id: 13,
        imgsrc: "https://yugalkunj.org/images/ramlala.png",
        title: "Shree Ram Lala Mandir Moorti Pran Pratishta ",
        lablename: "View Flyer ",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "",
        enddate:'23/01/2024',
        imglinks: "https://yugalkunj.org/images/ramlala.png"
        
    },
    {
        id: 10,
        imgsrc: "https://yugalkunj.org/images/yoga.png",
        title: "Yoga & Pranayam ",
        lablename: "Register Now ",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "https://ykportal.yugalkunj.org/",
        enddate:'31/03/2024',
        imglinks: "https://yugalkunj.org/images/yoga.png"
        
    },

    {
        id: 21,
        imgsrc: "https://yugalkunj.org/images/dance.png",
        title: "Break Dance Hip Hop ",
        lablename: "Register Now ",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "https://ykportal.yugalkunj.org/",
        enddate:'31/12/2023',
        imglinks: "https://yugalkunj.org/images/dance.png"
        
    },

    {
        id: 22,
        imgsrc: "https://yugalkunj.org/images/carnatic.png",
        title: "Classical Music ",
        lablename: "Register Now ",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "https://ykportal.yugalkunj.org/",
        enddate:'31/12/2023',
        imglinks: "https://yugalkunj.org/images/carnatic.png"
        
    },
    

    {
        id: 1,
        imgsrc: "https://stssevastorage.blob.core.windows.net/my-container/Financial%20Planning%20Class%20Flyer%202%20-%20800x800.jpg",
        title: "Finance Planning Concepts ",
        lablename: "Register Now ",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "",
        enddate:'04/02/2023',
        imglinks: "https://stssevastorage.blob.core.windows.net/my-container/Financial%20Planning%20Class%20Flyer%202%20-%20800x800.jpg"
        
    },


    {
        id: 2,
        imgsrc: "https://yugalkunj.org/images/NewYear.png",
        title: " 2024 NYE 12 Hour Akhand Sankeertan",
        lablename: "View Flyer",
        links: "https://yugalkunj.org/images/NewYear.png",
        pagelinks: "",
        enddate:'31/12/2023',
        imglinks: "https://yugalkunj.org/images/NewYear.png"

    },

    {
        id: 3,
        imgsrc: "https://yugalkunj.org/images/Class2.jpg",
        title: " Crochet Class",
        lablename: "Register Now",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "",
        enddate:'31/03/2023',
        imglinks: "https://yugalkunj.org/images/Class2.jpg"

    },

   
    {
        id: 4,
        imgsrc: "https://yugalkunj.org/images/RotiFlyer.jpeg",
        title: "Fresh Roti",
        links: "https://ykportal.yugalkunj.org/Tiffin/Home",
        lablename: "Order Now",
        pagelinks: "",
        enddate:'21/12/2023',
        
        imglinks: "https://yugalkunj.org/images/RotiFlyer.jpeg"

    },


   

    {
        id: 5,
        imgsrc: "https://yugalkunj.org/images/YugalKunjexpansion.jpg",
        title: "Yugal Kunj expansion",
        lablename: "View Flyer",
        links: "https://yugalkunj.org/images/YugalKunjexpansion.jpg",
        pagelinks: "",
      
        enddate:'23/10/2025',
        imglinks: "https://yugalkunj.org/images/YugalKunjexpansion.jpg"

    },

    
    
    {

        id: 6,
        title: "Devotional Retreat",
        links: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        pagelinks: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        lablename: "Please click here for registration",
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2023.png",
        enddate:'23/10/2023',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2023.png"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },
    
   
    {

        id: 7,
        title: "In-Person Gurukul {Hinduism} Class",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "",
        lablename: " Please click here for registration",
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/GurukulFlyers-ATL.jpg",
        enddate:'13/05/2024',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykwebsite/GurukulFlyers-ATL.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id: 8,
        title: "Virtual Hindi Classes",
        links: "https://ykportal.yugalkunj.org/",
        lablename: " Please click here for registration",
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/HindiFlyers.jpg",
        enddate:'06/05/2024',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykwebsite/HindiFlyers.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id: 9,
        title: "Prem Ras Siddhanth",
        links: "https://ykportal.yugalkunj.org/",
        pagelinks: "",
        lablename: " Please click here for registration",
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/PRS-Adult.jpg",
        enddate:'09/05/2024',
        imglinks: "https://stssevastorage.blob.core.windows.net/ykwebsite/PRS-Adult.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    }




]

export default EventCardData;