
const WordofWisdomeCard = () => {
    
    
    //   const saintsStyle = {
    //     fontStyle: 'italic',
    //     color: '#e74c3c',
    //   };

     
// const qaData = [
//     { question: "Decide to lose.", answer: "When engaged in a battle of words with a family member, decide to lose the argument. You will emerge victorious, with the twin bounty of peace and humility in your hands." },
//     { question: "Decide to say something.", answer: "If you hear someone insulting another or judging others by their looks, color, race or religion, decide to not remain quiet. Say something. Let it be known that you don’t agree." },
//     { question: "Decide to make every day special.", answer: "Take a decision that every day will be special. Decide to value every moment and be grateful for life." },


//     { question: "Decide to discover the joy in small things.", answer: "It’s human tendency to wait for happiness to come along. While we are waiting, we feel anxious. Decide to look for joy that surrounds you 24 hours a day." },
//     { question: "Decide to take time out for yourself.", answer: "You will not become a martyr to your family for sacrificing your health for their sake. You will be blamed instead. So, decide to take care of your physical and mental health." },
//     { question: "Decide to be spontaneous.", answer: "Take a walk in the middle of the day, or bake cookies, or play in the backyard with your children, or take a comedy break. Be spontaneous." },
//     { question: "Decide to break the tension.", answer: "Look at your face in the mirror and make a funny face. Shake off the tension and the gloom by laughing at yourself." },
    

    
//   ];



    return (

        <>  

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Blog Details--> */}
                                <div className="blog-details">


                                    <div className="lower-box">


                                        <div className="panel panel-primary">
                                            <div className="panel panel-heading">
                                                {/* <h4>Words of Wisdom - Question & Answer Session - #140   </h4> */}
                                                <h4>Words of Wisdom - Take a walk </h4>

                                            </div>

                                            <div className="panel panel-body">
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                       <img src="https://yugalkunj.org/images/wordofwisdom.jpg"/>

                                                <hr style={{backgroundColor:"#c3319a", height:"2px" }}/>
                                                  <p>If you are looking for a perfect exercise, look no further. Just walk. Walking is the best exercise and much more. There is no need for any gadget or equipment. It can be done anywhere by people of all ages. Over the years I have experienced many benefits from the simple act of walking and have become an ardent fan.</p>
                                                  <p> Walking exercises every part of the body. It is low impact; easier on the knees than jogging and running. You can walk fast, slow or retain a medium pace. But walking is no sissy either. If you wish to take it to the next step, you can attach weights to your ankles or hold them in your hands. </p>
                                                  <p> They who walk soon discover that this pleasurable exercise clears the head and defogs the brain. Do you have a problem? Go for a walk. Feeling down? Take a walk in the park. You will calm down considerably and the problem will not seem so serious. Not only this, the solution starts becoming obvious. </p>
                                                  <p> Walking is never boring. There is so much to think of and observe. But if you get bored, take your iPhone or iPod along. Put a spring in your walk by simultaneously listening to your favorite music. Enjoy your walk. And if you wish to hold a meaningful conversation with a person, go for a walk together in a natural setting. </p>
                                                  <p>Walking has been given more respect than other exercises. Nik Wallenda ‘walked’ across Niagara Falls. He did not run or jog. No. He walked. If you do not like a job or a situation, you never jog or swim away. You ‘walk’. To understand the other guy, you need to ‘walk’ in his shoes. No need to sprint. You just walk in his shoes.</p>
                                                  <p><strong>So…. take a walk…. but please take your own shoes!</strong></p>

                                                  {/* <p> Heal someone today by believing in him. Believe that he will succeed, and let him know what you think. By simply verbalizing your faith in him, you will exorcise the demons of doubt from his mind, increasing his chance to be successful.</p>
                                                  <p> Learn to recognize battle wounds. The anger your co-worker is showing may be the consequence of a bitter divorce or a recent loss. The frustration of your child may be due to the bullying she is not telling you about. The agitation of your elderly parents may in fact be the fear of death and dying. 
                                                    You would be amazed at how much you can do for others by simply caring about them. </p> */}
                                                    {/* <p> Heal others through words, actions and thoughts. In the process, you will become healed yourself.</p> */}
                                                       {/* <p> 1) To achieve the highest, you must feel yourself to be the lowest. <strong style={saintsStyle}> "Jagadguru Shri Kripalu Ji Maharaj"</strong> </p>
                                                       <p> 2) Wake up and worship God. If you are fond of sleeping, rest assured that there will come a day when death will come, and you will sleep for a long time. <strong style={saintsStyle}> "Sant Kabir"</strong> </p>
                                                       <p> 3) Only when a man sees this universe as God does the veil fall from his eyes; then that man, purified and cleansed, finds his whole vision changed. <strong style={saintsStyle}> Svami Vivekanand</strong></p>
                                                      <p>  4)  For prayer is nothing else but being on terms of friendship with God. <strong style={saintsStyle}> Saint Theresa</strong> </p>
                                                      <p>  5) I planted the creeper of love and silently watered it with my tears. Now the creeper has spread and on it grows the fruit of Devotion. <strong style={saintsStyle}> Meerabai</strong>  </p>
                                                      <p>  6) The desire for more and more wealth is dangerous. Cultivate the good sense to give up your desires. Be content with what you have. <strong style={saintsStyle}> Adi Shankaracharya</strong>  </p>
                                                      <p>  7) Wisdom enters through love, silence, and mortification. <strong style={saintsStyle}> St. John of the Cross</strong></p>
                                                      <p>  8)  Renounce the desire to look good in the eyes of others and instead desire to become good in the true sense of the word. <strong style={saintsStyle}> Jagadguru Shri Kripalu Ji Maharaj</strong></p> */}

                                                    
                                                      
                                                        {/* <p> Children are fearless; they are always trying something new. Adults would do well to get into the spirit of a child and do something new. I have a few ideas. I dare you to try them! </p> */}
                                                        {/* <ul className="wordofwisdon_list">
                                                            <li> For the one who has never done it……. I dare you to walk in the rain without an umbrella.
                                                            </li>

                                                            <li> For the stoic male………I dare you to express your emotions and know that it’s okay to cry. </li>

                                                            <li>  For the female……..I dare you to contain your emotions at times and not allow your heart to rule your mind.</li>

                                                            <li> For the person who doesn’t cook…….I dare you to prepare a meal.</li>

                                                            <li> For the worrywart……..I dare you to stop worrying and start living.</li>

                                                            <li>  For the married person……….I dare you to look at your spouse’s point of view. </li>

                                                            <li>   For the highly organized person…..I dare you to do something spontaneous.</li>
                                                            <li> For the procrastinator…….I dare you to finish an important task today. </li>
                                                            <li> For the angry person………I dare you to decide that anger is a harmful emotion.</li>
                                                            <li> For the critical person……..I dare you replace your critical behavior with an understanding heart. </li>
                                                            <li> For the lazy person……..I dare you to discover the joy of working hard.</li>

                                                            <li> For the workaholic……..I dare you to relax and sit a while without doing anything.</li>
                                                            <li> For the teenager……….I dare you to communicate with your family.</li>
                                                            <li> For the seniors……..I dare you to ignore your age and think young.</li>

                                                            <li> For the juniors………I dare you to learn from the seniors in your family.</li>
                                                            <li> For the social media addict………I dare you to live one entire weekend without social media.</li>
                                                            <li> For the video game addict……..I dare you to play outside and discover the joys of Nature.</li>
                                                            <li> For everyone reading this………I dare you to have a wonderful weekend. </li>
                                                      
                                                      
                                                      
                                                      
                                                        </ul> */}
                                                      
{/* 
                                                      <p>The horrific events that unfolded on September 11, 2001 sent shock waves throughout the U.S. 23 years later, we can still vividly remember where we were on that morning and recall the whole gamut of emotions we felt in the aftermath. Our world was never going to be the same again. A dark cloud hovered for a long time over our collective consciousness and individual minds.
                                                    </p>

                                                    <p> At the same time, there is always a silver lining behind every dark cloud. Every tragedy forces us to accept truths that are otherwise too uncomfortable for us to reflect on.  Every tragedy leaves us with lessons that we have no choice but to accept, simply because they stare us glaringly in the face. We saw on that sad day that life is unpredictable, and death is inevitable. When those who perished on that day left home in the morning they did not think that this was going to be their final day on earth. None of us is privy to that information.
                                                    </p>

                                                    <p> Tragedies also force us to accept that life doesn’t always remain the same. Life is not a stagnant pond. Life is an ever-flowing river. Things change; people change; situations change. The only constant in life is ‘change’.
                                                     </p>

                                                     <p> When faced with excruciatingly difficult situations in life, we learn that we humans are resilient creatures. When we are in the depths of despair, we have no way to go except up. We cry; we grieve; we become despondent, but then we rise up. We rebuild ourselves and continue to live, hopefully wiser, due to the lessons tragedy has taught us.
                                                    </p> */}

                                                    {/* <p> The caterpillar must shed its former self for the butterfly to arrive. Every ending leads to a new beginning.</p> */}
{/* 
                                                     <div>
      {qaData.map((item, index) => (
        <div key={index} >
          <h5>  {item.question}</h5>
          <p style={{fontSize: '20px', borderBottom:"1px solid #ccc" }}>  {item.answer}</p>
        </div>
      ))}
    </div>  */}
                                                     

                                                        <hr />
                                                        <p> Yours,</p>
                                                        <p> Didi Ji</p>
                                                        <p className="text-center"> Note: You may direct your spiritual questions to me
                                                            directly at</p>
                                                        <p className="text-center"> s_didi@radhamadhavsociety.org</p>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>




                                    </div>






                                </div>





                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}



        </>


    )

}

export default WordofWisdomeCard;